import React from 'react'
import styles from "./Textcomponent.module.css"
import { motion } from 'framer-motion'

function Textcomponent({Heading , Content}) {

  return (
    <div className={styles.mainContainer}>
        <div className={styles.redLine}>
        </div>

        <motion.div initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }} className={styles.heading}>{Heading}</motion.div>
        
        <div className={styles.Content}>
            {Content}
        </div>
    </div>
  )
}

export default Textcomponent