import React, { useState } from "react";
import styles from "./Product.module.css";
import Modal from "../PopUpModal/Modal";
import { motion } from "framer-motion";

function Product({ id, imageUrl, heading, line }) {
  const [showModal, setShowModal] = useState(false);
  showModal
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  return (
    <div className={styles.mainContainer}>
      <motion.div
        viewport={{ once: true }}
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.5,
        }}
        whileHover={{
          scale: 1.02,
          transition: { duration: 0.1 },
        }}
        className={styles.productcard}
        onClick={() => setShowModal(true)}
      >
        <div
          className={styles.imgcontainer}
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
        <div className={styles.headingcontainer}>
          <div className={styles.head}>{heading}</div>
          <div className={styles.line}>{line}</div>
        </div>
      </motion.div>
      {showModal && <Modal id={id} onClose={() => setShowModal(false)} />}
    </div>
  );
}

export default Product;

//
