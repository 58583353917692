import React from "react";
import styles from "./AppCard.module.css";
import { motion } from "framer-motion";
// import app1 from "../../resources/app1.jpg";
// import app2 from "../../resources/app2.jpg"
// import app3 from "../../resources/app3.png"
import app1 from "../../../../assets/Appcardsphotos/Home1.jpg";
import app2 from "../../../../assets/Appcardsphotos/sealing.png";
import app3 from "../../../../assets/Appcardsphotos/handling.jpg";
import { Link } from "react-router-dom";
import Textcomponent from "../../../branding/Textcomponent";


export const cardData = [
  {
    title: "Painting Application",
    text: "Technavia specializes in comprehensive painting automation solutions, offering turnkey projects that encompass the entire spectrum, from paint kitchen setup to robotics integration, applicator selection, and paint dosing equipment. Our expertise lies in tailoring the most suitable system by meticulously choosing the automation process, applicator, and paint circulation system.",
    imageUrl: app1,
    scrolltox: 0,
    scrolltoy: 0,
  },
  {
    title: "Sealing and Dispensing Application",
    text: "Technavia excels in providing robotic automated solutions for windshield sealing, hot melt dispensing in the lighting industry, and various other dispensing applications essential in the automotive and general industries. We have successfully implemented numerous robotic systems for underbody sealing and PVC coating in major OEMs. Our turnkey solutions encompass everything from pumps, dosing units, and dispensing equipment to automation, streamlining the entire process.",
    imageUrl: app2,
    scrolltox: 10,
    scrolltoy: 10,
  },
  {
    title: "Handling Application",
    text: "Technavia offers robust solutions for automated machine tending applications, utilizing either robotic arms or customized systems to seamlessly transfer components. Given the diverse nature of handling tasks, which vary based on processes, component types, and manufacturing applications, we take full turnkey responsibility for automation solutions.",
    imageUrl: app3,
    scrolltox: 10000,
    scrolltoy: 10000,
  },
];

const AppCard = () => {
  const handleReadMore = (index) => {
    console.log(`Read More for index ${cardData[index].scrolltox}  ${cardData[index].scrolltoy}`);
  };

  return (
    <div className={styles.container} id="Applications">
      
      <Textcomponent Heading='Applications' Content='At Technavia, we specialize in delivering comprehensive solutions for
          painting, sealant application, and material handling automation. With
          a track record of over 500 robot installations and more than 150
          successful projects, our team of highly experienced professionals are
          committed to providing top-tier engineering solutions tailored to meet
          your automation needs.'/>

      <div className={styles.cards}>
        {cardData.map((card, index) => (
          <div className={styles.card} key={index}>
            <div className={styles.cardContent}>
              <div className={styles.cardtitle}>
                <h3>{card.title}</h3>
              </div>
              <div className={styles.cardtext}>
                <p>{card.text}</p>
              </div>

              <Link to="/Applications">
                <button
                  className={styles.popup}
                  onClick={() => handleReadMore(index)}
                >
                  Read More
                </button>
              </Link>
            </div>
            <div
              className={styles.cardImage}
              style={{ backgroundImage: `url(${card.imageUrl})` }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppCard;
