import React from 'react'
import styles from './ImageText.module.css'
import {motion} from 'framer-motion';

function ImageText(props) {
  
  return (
    <div className={styles.mainContainer}>
        


        <motion.div initial={{ width: 0 }} viewport={{once:true}} whileInView={{width: '150px'}} transition={{ duration:1}} className={styles.line}></motion.div>

        <motion.div 
        key={props.heading}
        variants={{ 
            hidden:{ opacity: 0, x :-75},
            visible: { opacity:1, x:0},
        }}
        
        initial="hidden"
        animate="visible"
        transition={{ duration:0.5,
          type:"spring",
          delay: 0.1,
          stiffness: 100,}}
        className={styles.heading}>
            {props.heading}
        </motion.div>

        <motion.div
        variants={{ 
          hidden:{ opacity: 0, x :-75},
          visible: { opacity:1, x:0},
      }}
      initial="hidden"
      animate="visible"
      transition={{ duration:0.5,
        delay: 0.3}} className={styles.content}>
            {props.content}
        </motion.div>
    </div>
  )
}

export default ImageText