import React from 'react';
import Product from './Product';
import styles from "./Machines.module.css";
import productData from '../../../../dummydata';
import { motion } from 'framer-motion';

const Machine = () => {


  return (
    <div className={styles.componentWrapper} id="Products">
    <div className={styles.TextBox}>
      <div className={styles.redLine}></div>
      <motion.div initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }} className={styles.heading}>
        Our Products
      </motion.div>
      <motion.div initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{once:true}}
        transition={{ delay: 0.2, duration: 0.5 }} className={styles.content}>
      Discover the future of automation with our range of industrial robots and robot SCADA software. Our robots are designed to revolutionize your manufacturing processes, offering precision, efficiency, and reliability. Pair them with our cutting-edge SCADA software to optimize control and monitoring, ensuring seamless integration into your operations. Explore our range today and unlock the potential of smart manufacturing with our innovative solutions.
      </motion.div >
    </div>
    <div className={styles.maincontainer}>
    <div className={styles.mainprcontainer}>
      <div className={styles.productlog}>
        {productData.map(machine => (
          <div className={styles.producttag}>
          <Product key={machine.id} id={machine.id} imageUrl={machine.imgSrc[0]} heading={machine.heading} line={machine.introLine}/>
          </div>
        ))}
      </div>
    </div>
    </div>
        </div>
  );
};

export default Machine;
