import React, { useRef } from 'react'
import styles from './Modal.module.css'
import ProductImages from './ProductImages';
import productData from '../../../../dummydata';

function Modal({onClose , id}) {

  const product = productData.find(product=> product.id === id);

  const modalRef = useRef();
  const closemodal =(e) => {
    if (modalRef.current === e.target){
      onClose()
    }

  }


  return (
    <div ref={modalRef} className={styles.modalContainer} onClick={closemodal}>
      <div className={styles.mainContainer}>

        <div className={styles.productCard}>
          <ProductImages id={id}/>
          <div className={styles.productText}>
            <div className={styles.heading}>{product.heading}</div>
            <div className={styles.scroll}>
              <div className={styles.content}>
                <p>
                 {product.Paragraph}
                </p>
            <div className={styles.points}>
              {product.points.map((points)=>(
                <li>{points}</li>
              ))}
            </div>
              </div>
            </div>
          </div> 
<button onClick={onClose}>x</button>
                  </div>
      </div>
    </div>
  );
}
export default Modal