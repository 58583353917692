import photo from "../../../assets/GroupPic.jpeg";

import applicator1 from "../../../assets/ApplicationsPhotos/Applicator1.png"
import applicator3 from "../../../assets/ApplicationsPhotos/Applicator3.png"

import ccv1 from "../../../assets/ApplicationsPhotos/ccv1.jpg"
import ccv2 from "../../../assets/ApplicationsPhotos/ccv2.jpg"
import ccv3 from "../../../assets/ApplicationsPhotos/ccv4.jpg"

import pcs1 from "../../../assets/ApplicationsPhotos/pcs1.png"
import pcs2 from "../../../assets/ApplicationsPhotos/pcs2.png"

import ps1 from "../../../assets/ApplicationsPhotos/ps1.png"
import ps2 from "../../../assets/ApplicationsPhotos/ps2.jpg"
import ps3 from "../../../assets/ApplicationsPhotos/ps3.jpg"

import eap1 from "../../../assets/ApplicationsPhotos/E&Ppanel1.png"
import eap2 from "../../../assets/ApplicationsPhotos/E&Ppanel2.png"
import eap3 from "../../../assets/ApplicationsPhotos/E&Ppanel3.jpg"

import tv1 from "../../../assets/ApplicationsPhotos/tv1.png"
import tv2 from "../../../assets/ApplicationsPhotos/tv2.png"
import tv3 from "../../../assets/ApplicationsPhotos/tv3.png"


const PaintingApplicationsData = [
  {
    headline: "Applicator",
    content: (
      <>
        <p>
          Applicator selection is pivotal in attaining desired outcomes in line
          with specific conditions. At Technavia, we conduct comprehensive
          analyses of the process, components involved, desired quality
          standards, transfer efficiency requirements, and overall return on
          investment (ROI) considerations. This holistic approach ensures the
          proposal of the most fitting applicator solution, aligned precisely
          with the project's objectives and performance expectations.
        </p>
      </>
    ),

    imageSrc: photo,
    extraImages: [applicator1, applicator3],
  },
  {
    headline: "Colour Changing Unit",
    content: (
      <>
        <p>
          Our colour changers epitomize precision engineering, optimized for
          swift colour transitions, just like all our components. Our diverse
          product range includes single colour changers, double colour changers,
          star colour changers, and inline colour changers, all crafted with
          precision from stainless steel. Precision valve borings ensure
          long-lasting performance and reliable switching of paint valves. For
          flushing the colour changers, we provide options such as air/solvent
          units and power cleaners.
        </p>

        <br />

        <p>
          <u>Single Colour Changers:</u> These modular and scalable units
          facilitate two-colour operations. Switching occurs inside the
          distributor block through pneumatically powered paint valves, with or
          without a switching display. Paint connectors are available in various
          hose sizes, catering to both static and circulatory line applications.
        </p>

        <br />

        <p>
          <u>Double / A-B Colour Changers:</u> Our modular 2-channel units are
          designed for alternating colour operations. Featuring the same
          precision engineering as our single changers, they offer versatile
          solutions for colour switching needs.
        </p>

        <br />

        <p>
          <u>Customized Solutions:</u> Upon request, we specialize in crafting
          custom-built colour changers to precisely meet your unique
          specifications and requirements.
        </p>

        <br />

        <p>
          <u>2K mixer:</u> Introducing the 2K-MIXER: Our 2-component mixers are
          engineered to precisely blend and deliver two components, such as
          paint and hardener, ensuring optimal performance. Designed with
          scalable modules, the mixer elements offer flexibility and can be
          customized with various hose connections to suit your specific
          requirements. Available in stainless steel and plastic, our range
          includes a variety of static mixer elements renowned for their
          durability and reliability.
        </p>

        <br />

        <p>
          The versatility of our 2K-MIXER allows for integration into paint
          application cabinets or mounting onto the processor arm of painter
          robots, providing seamless and efficient mixing solutions tailored to
          your needs.
        </p>
      </>
    ),
    imageSrc: photo,
    extraImages: [ccv3, ccv2,ccv1],
  },
  {
    headline: "Paint circulation system",
    content: (
      <>
        <p>
           Elevate Your Painting Process with
          Technavia's Integrated Solutions!
        </p>
        <br />
        <p>
          At Technavia, we seamlessly integrate painting applicators and robots
          with the most optimal paint circulation systems available. Leveraging
          our extensive expertise and technological advancements derived from
          years of robotic painting projects, we engineer sophisticated paint
          circulation systems. Our solutions include constant-feed devices for
          1K, 2K, and 3K components, ensuring precise mixing ratios and
          consistent performance. Trust Technavia for cutting-edge solutions
          that redefine efficiency and precision in painting automation.
        </p>
      </>
    ),
    imageSrc: photo,
    extraImages: [pcs1, pcs2],
  },
  {
    headline: "Pigging System",
    content: (
      <>
        <p>
          Experience Enhanced Efficiency with Technavia's Pigging System
          Solutions
        </p>
        <br />
        <p>
          Technavia offers comprehensive solutions that include pigging systems,
          revolutionizing painting facilities' efficiency. Our pig systems
          optimize economy by minimizing paint changeover times and recovering
          valuable materials. With the pigging system, excess paint and hardener
          are efficiently returned to the supply units, ensuring optimal purity.
          Additionally, it swiftly removes excess pipe content from piping
          systems with minimal loss, leaving no residue behind. Trust Technavia
          for advanced pigging solutions that streamline operations and maximize
          resource utilization.
        </p>
      </>
    ),
    imageSrc: photo,
    extraImages: [ps3 , ps2 , ps1],
  },
  {
    headline: "Electrical & Pneumatic control system",
    content: (
      <>
        <p>
          At Technavia, we boast an in-house facility dedicated to crafting
          electrical control panels tailored to your specifications, complete
          with the desired PLCs to meet your unique requirements. Prioritizing
          quality and precision, our off-line testing facility meticulously
          evaluates system logics before dispatch, ensuring flawless performance
          upon installation.
        </p>

        <br />

        <p>
          Specializing in customized pneumatic control panels for automatic
          painting systems, we adhere to international standards by integrating
          renowned components into our panels. Trust Technavia for reliable,
          high-quality control panel solutions that seamlessly integrate with
          your painting line, optimizing efficiency and performance.
        </p>
      </>
    ),
    imageSrc: photo,
    extraImages: [eap1, eap2, eap3],
  },
  {
    headline: "SCADA System-TECHNAVIEW",
    content: (
      <>
        <p>
          Technavia's SCADA (Supervisory Control and Data Acquisition) system
          employs advanced control system architecture, leveraging computers,
          networked data communication, and intuitive graphical user interfaces
          for comprehensive process supervisory management. Integrated with PLC
          technology, SCADA efficiently controls devices within the system and
          displays real-time data on screen.
        </p>
        <br />
        <p>
          Our SCADA solution significantly boosts productivity by simplifying
          the monitoring of various parameters throughout the painting process.
          Aligned with industry 4.0 standards, our SCADA system ensures seamless
          integration with modern manufacturing practices, empowering businesses
          with enhanced operational efficiency and performance monitoring
          capabilities. Trust Technavia for cutting-edge SCADA solutions
          tailored to your needs, driving productivity and success in your
          operations.
        </p>
      </>
    ),
    imageSrc: photo,
    extraImages: [tv1, tv2, tv3],
  },
];

export default PaintingApplicationsData;
