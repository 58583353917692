import React, { useState } from "react";
import styles from "./PaintingAppCards.module.css";
import PaintingAppData from "../PaintingAppData";
import ExtraImages from "./ExtraImages";

function PaintingAppCards() {
  const [selectedImageIndexes, setSelectedImageIndexes] = useState(Array(PaintingAppData.length).fill(0));

  const handleImageClick = (index, index1) => {
    setSelectedImageIndexes(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = index1;
      return newIndexes;
    });
  };

  return (
    <div className={styles.wrapper}>
      {PaintingAppData.map((data, index) => (
        <div  className={`${styles.DataContainer} ${index % 2 === 0 ? styles.left : styles.right}`}>
          <div className={styles.textBox}>
            <div className={styles.arrange}>
              <h1>{data.headline}</h1>
              <p>{data.content}</p>
            </div>
            <ExtraImages
              images={PaintingAppData[index]?.extraImages || []}
              onImageClick={(index1) => handleImageClick(index, index1)}
            />
          </div>

          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${PaintingAppData[index]?.extraImages[selectedImageIndexes[index]]})` }}
          ></div>

        </div>
      ))}
    </div>
  );
}

export default PaintingAppCards;
