import React, { useRef } from "react";
import styles from "./Nav2.module.css";
import { Link as RouterLink } from "react-router-dom";

function Nav2() {
  const navRef2 = useRef();

  const showNavbar = () => {
    navRef2.current.classList.toggle(styles.responsiveNav);
  };

  return (
    <div className={styles.header}>
      <RouterLink
        activeClass={styles.selected}
        to="/"
        className={styles.companylogo}
      ></RouterLink>
      <nav ref={navRef2}>
        <RouterLink
          activeClass={styles.selected}
          className={styles.hov}
          to="/"
          onClick={showNavbar}
        >
          {" "}
          Back to home{" "}
        </RouterLink>
      </nav>
    </div>
  );
}

export default Nav2;
