import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Router,
  RouterProvider,
}
from 'react-router-dom';


import Applications from "./Routes/ApplicationsPage/Applications/Applications"
import HomePage from "./Routes/HomePage/HomePage";
import Services from "./Routes/Services/Service";


const routeDefinitions = createRoutesFromElements(
  <Route>
    <Route path='/' element={<HomePage/>} />
    <Route path='applications' element={<Applications/>} />
    <Route path='services' element={<Services/>} />

  </Route>

);

const router = createBrowserRouter(routeDefinitions);

function App() {

  return (
        <RouterProvider router={router}/>
  );
}

export default App;
