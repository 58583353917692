import image from '../src/assets/ProductPhotos/first.png'
import image2 from '../src/assets/ProductPhotos/second.jpg'
import image3 from '../src/assets/ProductPhotos/third.webp'
import image4 from '../src/assets/ProductPhotos/fourth.webp'
import test from '../src/assets/fb.png'
import vertex from '../src/assets/vertax.jpg'
import technaView from '../src/assets/scada2.jpeg'
import feeder from '../src/assets/ProductPhotos/technafeeder.jpg'
import slink from '../src/assets/ProductPhotos/technaslink.jpg'
import pigging from '../src/assets/ProductPhotos/PiggingSystem.png'
import dosing from "../src/assets/ProductPhotos/FGP.jpg"
import techna from '../src/assets/ProductPhotos/Technaview1.jpg'

import bellcleaner1 from "./assets/ProductPhotos/bellcleaner.jpg"
import ccv from "./assets/ProductPhotos/ccv3.jpg"
import tf2 from "./assets/ProductPhotos/TF2.jpeg"
import dos1 from "./assets/ProductPhotos/dos1.jpg"
import dos2 from "./assets/ProductPhotos/dos2.jpg"

import tv from"./assets/ApplicationsPhotos/tv1.png"
import tv2 from"./assets/ApplicationsPhotos/tv2.png"
import tv3 from"./assets/ApplicationsPhotos/tv3.png"
const productData = [
  {
    id: 1,
    heading: "TECHNAFEEDER",
    introLine:"Precision painting made reliable and affective for light components",
    Paragraph:
      "Introducing Technavia's Technafeeder, featuring a versatile 4 and 2 spindle feeder ideal for light components such as lenses and bezels. With two servo motors, it offers precise rotation for optimal painting. Adjustable fixture angles provide enhanced accessibility for the robot, regardless of component profile. Plus, its pressurized chamber ensures reliability even in hazardous environments.",
    points: [
      "Compact and versatile, our two-axis rotary feeder streamlines the workflow.",
      "Tailored to the requirement with table pitches ranging from 1400mm to 1800mm, ensuring seamless integration into the workspace.",
      "Maximize the floor space by effortlessly mounting the robot above the Technafeeder.",
      "Drive efficiency with only two servo motors powering 5 axes, saving energy without compromising performance.",
      "Say goodbye to maintenance headaches with our maintenance-free AC servo motor.",
      "Revolutionize the productivity by painting multiple workpieces simultaneously.",
      "Achieve flawless finishes with optimal spray angle of a robot, adapting effortlessly to diverse workpiece shapes and sizes.",
      "Seamlessly teach spindle rotations and reset positions with the servo motor's endless rotary function, simplifying operation.",
      "Experience unparalleled smoothness in operation, thanks to our gear and belt transmission systems.",
    ],

    imgSrc: [feeder , tf2],
    video: "https://www.youtube.com/embed/7RQMQ1kgMF0?si=GCYEwLM7nHEUcZ00&amp;start=1",
    video1: "https://www.youtube.com/embed/qgd8UjK7e7U?si=mOr84VO-DlfE1Zp-",
  },
  {
    id: 2,
    heading: "VERTEX & HORAX",
    introLine:"Engineered solution for vertical and horizontal painting.",
    Paragraph:
      "Introducing Technavia's Reciprocator Series: VERTAX and HORAX. Elevate your painting process with our precision-designed solutions. Designed for vertical and horizontal painting methods, our series offers three variants categorized by stroke length. Experience efficiency in both liquid and powder coating lines, supported by a high-load capacity servo-driven system. Explore our disc applicator model, perfect for omega-shaped booths. Revolutionize your painting workflow with Technavia's innovative reciprocator technology.",
    points: [
      "Experience long-stroke and short-stroke precision.",
      "Enjoy high loading capacity and stability for flawless operation.",
      "Optimize space with a slim column design, arranging guns seamlessly.",
      "Achieve unparalleled smoothness with toothed belt transmission.",
      "Eliminate maintenance concerns with our AC servo motor.",
      "Customize motion with single-axis operation and variable strokes.",
      "Seamlessly program via HMI Control Unit for effortless operation.",
      "Store up to 100 programs for diverse workpieces.",
      "Tailor speed to perfection with variable speed control."
    ],

    imgSrc: [vertex ],
    video: "https://www.youtube.com/embed/FG3DHZwQmO8?si=h4Gbe-nuLMlvQ7m-",
},
{
    id: 3,
    heading: "TECHNASLINK",
    introLine:"Redefining robot manoeuvrability and precision",
    Paragraph:
      "Introducing Technavia's Single Axis Servo Slider, a revolutionary addition to your robotic setup. Enhance your robot's mobility and accessibility with this innovative system. By synchronizing the movement of your robot with the slider, you can achieve enhanced coverage and precision when teaching tasks across a variety of components. This seamless integration allows for smoother and more efficient operations, opening new possibilities for automation in diverse industries.",
    points: [
      "Achieve best-in-class path and motion accuracy for optimal performance.",
      "Versatile design fits seamlessly with any robot model, ensuring compatibility.",
      "Engineered with the highest quality components for unbeatable reliability.",
      "Choose from a wide range of options tailored to diverse configurations.",
      "Enhance robotic arm accessibility across various applications for maximum versatility.",
      "Effortless maintenance and superior reliability ensure continuous operation."
    ],

    imgSrc: [slink],
    video: "https://www.youtube.com/embed/DrocscA2gYM?si=2oEHqd7kvcC1pKWd&amp;start=1",
  },
  {
    id: 4,
    heading: "TECHNAVIEW",
    introLine:"Advanced control with real-time monitoring.",
    Paragraph:
      "Introducing Technavia's Technafeeder, featuring a versatile 4 &amp; 2 spindle feeder ideal for light components such as lenses and bezels. With two servo motors, it offers precise rotation for optimal painting. Adjustable fixture angles provide enhanced accessibility for the robot, regardless of component profile. Plus, its pressurized chamber ensures reliability even in hazardous environments.",
    points: [
      "Compact and versatile, our two-axis rotary feeder streamlines the workflow.",
      "Tailored to the requirement with table pitches ranging from 1400mm to 1800mm, ensuring seamless integration into the workspace.",
      "Maximize the floor space by effortlessly mounting the robot above the Technafeeder.",
      "Drive efficiency with only two servo motors powering 5 axes, saving energy without compromising performance.",
      "Say goodbye to maintenance headaches with our maintenance-free AC servo motor.",
      "Revolutionize the productivity by painting multiple workpieces simultaneously.",
      "Achieve flawless finishes with optimal spray angle of a robot, adapting effortlessly to diverse workpiece shapes and sizes.",
      "Seamlessly teach spindle rotations and reset positions with the servo motor's endless rotary function, simplifying operation.",
      "Experience unparalleled smoothness in operation, thanks to our gear and belt transmission systems.",
    ],

    imgSrc: [techna,technaView , tv,tv2,tv3],
    video: "https://www.youtube.com/embed/dByvPIyIbZE?si=gpQVpP-48X8FiyAL",
},
{
    id: 5,
    heading: "BELL CLEANER",
    introLine:"Automated, efficient, and versatile cleaning technology",
    Paragraph:
      "Introducing Technavia's innovative Bell Cleaner System, designed to safeguard the bell shroud from damage through automated cleaning processes. Our system employs a state-of-the-art high-speed rotary mechanism, efficiently injecting solvent followed by precisely directed air application. This meticulously sequenced procedure guarantees thorough cleaning without the need for manual intervention, ensuring optimal performance and longevity of your equipment.",
    points: [
      "With its versatile design offering both wall and floor mounting options, our Bell Cleaner System can be effortlessly adapted to suit your specific requirements. This flexibility allows for seamless integration into various operational setups, enhancing convenience and efficiency.",
      "Experience peace of mind and streamline maintenance procedures with Technavia's cutting-edge bell cleaner technology. Protect your investment and maximize the lifespan of your equipment with our reliable and innovative cleaning solution.",
    ],

    imgSrc: [bellcleaner1 ],
    video: "https://www.youtube.com/embed/1nYg-AgAmqQ?si=op1KoFnIgJPVryJF",
  },
  {
    id: 6,
    heading: "PIGGING SYSTEM",
    introLine:"Experience enhanced efficiency with Technavia's pigging system solution",
    Paragraph:
      "Technavia offers comprehensive solutions that include pigging systems, revolutionizing painting facilities' efficiency. Our pig systems optimize economy by minimizing paint changeover times and recovering valuable materials. With the pigging system, excess paint and hardener are efficiently returned to the supply units, ensuring optimal purity. Additionally, it swiftly removes excess pipe content from piping systems with minimal loss, leaving no residue behind. Trust Technavia for advanced pigging solutions that streamline operations and maximize resource utilization.",
    points: [
      // "Compact and versatile, our two-axis rotary feeder streamlines the workflow.",
      // "Tailored to the requirement with table pitches ranging from 1400mm to 1800mm, ensuring seamless integration into the workspace.",
      // "Maximize the floor space by effortlessly mounting the robot above the Technafeeder.",
      // "Drive efficiency with only two servo motors powering 5 axes, saving energy without compromising performance.",
      // "Say goodbye to maintenance headaches with our maintenance-free AC servo motor.",
      // "Revolutionize the productivity by painting multiple workpieces simultaneously.",
      // "Achieve flawless finishes with optimal spray angle of a robot, adapting effortlessly to diverse workpiece shapes and sizes.",
      // "Seamlessly teach spindle rotations and reset positions with the servo motor's endless rotary function, simplifying operation.",
      // "Experience unparalleled smoothness in operation, thanks to our gear and belt transmission systems.",
    ],

    imgSrc: [pigging ,],
    video: "https://www.youtube.com/embed/NucPPnyW6f4?si=hpd51vA9fYnxZ9nN",
},
{
    id: 7,
    heading: "DOSING UNIT(FGP)",
    introLine:"Experience unmatched precision with Technavia's dosing equipment",
    Paragraph:
      "Introducing our Flushable Gear Pump (FGP), a compact dosing unit designed through a collaborative effort between Technavia and POMA Systems. The FGP offers precision in paint dosing, streamlining operations and minimizing paint wastage during color changes when mounted on a robot arm.",
      points: [
        "Wide adjustment range for paint volume control via servo speed regulation.",
        "Minimizes paint residue in components, reducing contamination.",
        "Remarkably efficient in color changeover times.",
        "Swift purging process, leading to reduced purging agent consumption.",
        "Maintains consistent metering accuracy through automatic pressure adjustments.",
        "Key operational components crafted from durable stainless steel.",
        "Requires minimal maintenance, ensuring prolonged usability.",
        "Space-saving design optimized for robot arm mounting.",
        "Utilizes high-quality components for an extended operational lifespan.",
        "Equipped with an explosion-proof servo motor for operation in hazardous environments."
        ],

    imgSrc: [dosing  ,dos2],
    video: "https://www.youtube.com/embed/dByvPIyIbZE?si=gpQVpP-48X8FiyAL",
  },
  {
    id: 8,
    heading: "COLOUR CHANGING UNIT",
    introLine:"Precision-engineered colour changers for swift colour transitions.",
    Paragraph:
    (
      <>
        <p>
          Our colour changers epitomize precision engineering, optimized for
          swift colour transitions, just like all our components. Our diverse
          product range includes single colour changers, double colour changers,
          star colour changers, and inline colour changers, all crafted with
          precision from stainless steel. Precision valve borings ensure
          long-lasting performance and reliable switching of paint valves. For
          flushing the colour changers, we provide options such as air/solvent
          units and power cleaners.
        </p>

        <br />

        <p>
          <u>Single Colour Changers:</u> These modular and scalable units
          facilitate two-colour operations. Switching occurs inside the
          distributor block through pneumatically powered paint valves, with or
          without a switching display. Paint connectors are available in various
          hose sizes, catering to both static and circulatory line applications.
        </p>

        <br />

        <p>
          <u>Double / A-B Colour Changers:</u> Our modular 2-channel units are
          designed for alternating colour operations. Featuring the same
          precision engineering as our single changers, they offer versatile
          solutions for colour switching needs.
        </p>

        <br />

        <p>
          <u>Customized Solutions:</u> Upon request, we specialize in crafting
          custom-built colour changers to precisely meet your unique
          specifications and requirements.
        </p>

        <br />

        <p>
          <u>2K mixer:</u> Introducing the 2K-MIXER: Our 2-component mixers are
          engineered to precisely blend and deliver two components, such as
          paint and hardener, ensuring optimal performance. Designed with
          scalable modules, the mixer elements offer flexibility and can be
          customized with various hose connections to suit your specific
          requirements. Available in stainless steel and plastic, our range
          includes a variety of static mixer elements renowned for their
          durability and reliability.
        </p>

        <br />

        <p>
          The versatility of our 2K-MIXER allows for integration into paint
          application cabinets or mounting onto the processor arm of painter
          robots, providing seamless and efficient mixing solutions tailored to
          your needs.
        </p>
      </>
    ),
    points: [
      "Compact and versatile, our two-axis rotary feeder streamlines the workflow.",
      "Tailored to the requirement with table pitches ranging from 1400mm to 1800mm, ensuring seamless integration into the workspace.",
      "Maximize the floor space by effortlessly mounting the robot above the Technafeeder.",
      "Drive efficiency with only two servo motors powering 5 axes, saving energy without compromising performance.",
      "Say goodbye to maintenance headaches with our maintenance-free AC servo motor.",
      "Revolutionize the productivity by painting multiple workpieces simultaneously.",
      "Achieve flawless finishes with optimal spray angle of a robot, adapting effortlessly to diverse workpiece shapes and sizes.",
      "Seamlessly teach spindle rotations and reset positions with the servo motor's endless rotary function, simplifying operation.",
      "Experience unparalleled smoothness in operation, thanks to our gear and belt transmission systems.",
    ],

    imgSrc: [ccv,dos1 ]}

];
export default productData;