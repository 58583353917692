import React from 'react'
import styles from './OurValues.module.css'
import OurValuesTxt from './OurValuesTxt'
function OurValues() {
  return (
    <div x className={styles.mainContainer}>
        <div  className={styles.wrapper}>
            <OurValuesTxt/>
            <div className={styles.imageContainer}>
            </div>
        </div>
    </div>
  )
}

export default OurValues