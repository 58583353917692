import React, { useEffect } from "react";
import styles from "./Service.module.css";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import servicetwo from "../../assets/ServicesPhoto/servicetwo.png";
import servicethree from "../../assets/ServicesPhoto/servicethree.jpg";
import servicefour from "../../assets/ServicesPhoto/servicefour.jpg";
import mech from "../../assets/ServicesPhoto/mech.png"
import electric from "../../assets/ServicesPhoto/electric.jpg"
import Nav2 from "../branding/Nav2"
import robotlab from "../../assets/ServicesPhoto/robotlab.jpg"
import image9 from "../../assets/ServicesPhoto/image9.jpg"
import spare from "../../assets/SpareParts.png"
import design from "../../assets/ServicesPhoto/Design.png"
function ScrollToTopOnMount() {
  const { services } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [services]);

  return null;
}

const contentData = [
  {
    headline: "System Upgradation",
    text: "At Technavia, we excel not only in delivering cutting-edge systems for greenfield projects but also in upgrading existing application processes to introduce a fresh, revamped solution. Our team meticulously assesses the limitations of the current system and collaborates with clients to offer a range of optimal solutions, considering both technical specifications and commercial viability. Refurbishing an existing system presents various challenges, but with our expert engineering team, we meticulously explore every aspect to elevate the system to new heights and ensure seamless integration with the latest technology trends. Trust Technavia for innovative solutions that transform your processes and drive sustainable growth.",
    imageSrc: servicetwo,
    altText: "Image 2",
  },
  {
    headline: "Robot Teaching",
    text: "Technavia boasts a dedicated team specialized in teaching new model robots. Our engineering experts commence with offline teaching, meticulously assessing robot accessibility and determining optimal teaching trajectories to meet required cycle times. With extensive experience, our team has successfully executed numerous global projects involving teaching new models across various robot manufacturers. Trust Technavia for expert guidance in maximizing the efficiency and performance of your robotic systems.",
    imageSrc: servicethree,
    altText: "Image 3",
  },
  {
    headline: "Training",
    text: "Technavia offers comprehensive professional training on both robots and applications to empower our customers with in-depth knowledge of the equipment we provide. Our training programs are designed to ensure safe and efficient operation of the system by operators. In our basic training sessions, we prioritize safety protocols and operational procedures, equipping operators to handle the system independently and effectively troubleshoot errors during breakdowns. For advanced training, we delve into the intricacies of the machines we supply, covering optional functions of the robot and emphasizing the critical aspects of preventive maintenance to maximize system performance. Technavia boasts dedicated training facilities equipped with training robots and peripheral painting equipment, providing hands-on learning experiences for our customers. Trust Technavia for comprehensive training solutions that enhance operational capabilities and system reliability.",
    imageSrc: servicefour,
    altText: "Image 4",
  },
  {
    headline: "Design Engineering",
    text: "At Technavia, we conduct comprehensive research to develop innovative concepts for painting and handling applications. Our capabilities encompass simulation studies and offline programming across various robot manufacturers. We conduct thorough analyses, including cycle time studies and robot model selections tailored to each application. Our design engineering team excels in designing customized systems to meet specific application requirements. Through our commitment to developing new products, our team contributes to the MAKE IN INDIA campaign, playing a crucial role in promoting domestic manufacturing and innovation. Trust Technavia for pioneering solutions that drive progress and support local industries.",
    imageSrc: design,
    altText: "Image 5",
  },
  {
    headline: "Electrical  manufacturing",
    text: "Experience the pinnacle of expertise with Technavia, where innovation meets precision in the design and manufacture of industrial control panels. Our dedicated electrical design and manufacturing department stands ready to tailor solutions to your exacting needs, surpassing off-the-shelf limitations with unparalleled mastery. Empowered by a team of seasoned professionals, we navigate through control system complexities to offer bespoke solutions, seamlessly integrating Motor Control Panels, PLCs, Servos, VFD-based panels, and a myriad of robotic systems. As esteemed channel partners of leading international brands in PLCs, servo drives, inverters, sensors, vision systems, and AC servo systems, we ensure compatibility and excellence at every step. Elevate your control system journey with Technavia – where ingenuity knows no bounds.",
    imageSrc: electric,
    altText: "Image 5",
  },
  {
    headline: "Robotic Painting trial",
    text: "Technavia boasts a state-of-the-art paint laboratory on its premises, complete with a paint trial booth, precision painting robot, advanced applicator, paint feeding system, colour-changing valve, gear pump assembly, pigging system, and a specialized oven. Here, we meticulously inspect painting quality and simulate conditions mirroring those of our clients&#39; paint booths. This setup not only enables us to anticipate and address potential issues but also empowers us to achieve the desired quality by fine-tuning painting parameters to exact specifications. We conduct trials on components up to the size of a bumper, with each trial accompanied by a comprehensive report detailing all parameters set for that component.",
    imageSrc: robotlab,
    altText: "Image 5",
  },
  {
    headline: "Pre-installation test and trials",
    text:( 
      <>
    <p>Technavia is dedicated to delivering systems that operate seamlessly within the conditions agreed upon with the client. To ensure this, we meticulously test each system at our state-of-the-art facility, proactively identifying and addressing any potential issues before installation. Our rigorous testing protocol encompasses thorough examinations of mechanical and electrical components, guaranteeing system stability and minimizing the risk of breakdowns on-site. By conducting comprehensive tests, we streamline the installation process, ultimately saving valuable time. Our testing scope includes:</p>
      <ul>
        <li>Rigorous testing and meticulous routing of pneumatic and paint hoses, ensuring they are prepared for a seamless "plug and play" system integration.</li>
        <li>Thorough testing of Colour-Changing Valves (CCV), Flushable Gear Pumps (FGP), and applicator operations to ensure optimal functionality.</li>
        <li>Comprehensive testing of robot and electric units.</li>
        <li>Evaluation of electric panels and communication with peripheral devices.</li>
        <li>Thorough examination of all additional machinery within the system.</li>
        <li>Testing of painting robotic systems and associated peripheral devices.</li>
        <li>Rigorous safety testing to ensure system reliability and user security.</li>
      </ul>
      </>
      ),
    imageSrc: image9,
    altText: "Image 5",
  },
  {
    headline: "Mechanical  manufacturing ",
    text: "At Technavia, our capabilities extend beyond just electrical components – we proudly produce a diverse range of mechanical items integral to our systems. With a seasoned team of design and manufacturing experts, we uphold a steadfast commitment to delivering superior quality in every in-house manufactured piece.Our dedicated workforce spans production, assembly, quality assurance, and machine operation, united in their mission to produce robust products that elevate the performance of our systems. Through our in-house manufacturing and assembly processes, we inspire confidence in the reliability and excellence of every system we offer. This self-reliance ensures that quality remains firmly within our grasp, empowering us to swiftly address any issues that may arise, guaranteeing seamless performance and customer satisfaction. Our team of assembly conduct all the assemblies and trials of system before they are made to dispatch.",
    imageSrc: mech,
    altText: "Image 5",
  },
  {
    headline: "Spare Part Inventory",
    text: "Technavia maintains an extensive spare inventory to ensure efficient service backup for our customers. Our inventory planning prioritizes critical parts and items with high lead times, ensuring prompt availability when needed. Regular inspections by our dedicated team ensure the ongoing condition of system parts, with timely recommendations for repairs or replacements to ensure smooth machine operation and prolong equipment lifespan. Trust Technavia for proactive maintenance strategies that optimize equipment performance and reliability.",
    imageSrc: spare,
    altText: "Image 1",
  },
];

function Service() {
  return (
    <>
    <ScrollToTopOnMount/>
    <Nav2/>
    <div className={styles.service}>
      <div className={styles.overlay}>
        <div className={styles.redLine}></div>
        <h1 className={styles.overlayHeading}>OUR SERVICE POLICY</h1>
        <p className={styles.overlayContent}>
          In the event of breakdowns on-site, Technavia goes beyond mere
          analysis of root causes; we proactively establish improvement plans
          and transparently communicate issues to our customers. Our approach
          involves swift application of solutions on-site, closely monitored for
          maximum efficiency.
          <br />
          <br />
          We adhere to a standardized process, regularly sharing and addressing
          issues through systematic checks and non-operational inspections. This
          allows us to schedule preventive maintenance plans customized to each
          equipment's specific requirements. In our commitment to proactive
          maintenance, we implement predictive strategies by continuously
          managing equipment history. Furthermore, we provide comprehensive
          manuals for addressing failures and deploy monitoring systems to
          ensure thorough oversight of equipment performance. Trust Technavia
          for proactive maintenance solutions that keep your operations running
          smoothly and prevent future disruptions.
        </p>
      </div>

      <div className={styles.wrapper}>
        {contentData.map((content, index) => (
          <div key={index} className={`${styles.article}  ${index % 2 === 0 ? styles.left : styles.right}`}
          >
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${content.imageSrc})` }}
              ></div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, duration: 1 }}
              className={styles.contentContainer}>
              <h2 className={styles.headline}>{content.headline}</h2>
              <p className={styles.servicepara}>{content.text}</p>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
        </>
  );
}

export default Service;