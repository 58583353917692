import React, { useRef } from 'react'
import styles from './AppNav.module.css'
import { Link } from "react-scroll";
import { Link as RouterLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

function AppNav() {

    const navRef2 = useRef();

	const showNavbar = () => {
		navRef2.current.classList.toggle(
			styles.responsiveNav
		);
	};
	
    return (
		<header>
            <RouterLink activeClass={styles.selected} to="/" className={styles.companylogo}>
            </RouterLink>
			<nav ref={navRef2}>
				
				<RouterLink activeClass={styles.selected} className={styles.hov} to="/" onClick={showNavbar}> Back to home </RouterLink>
				<Link activeClass={styles.selected} className={styles.hov} to="Painting" spy={true} smooth={true} offset={-180} duration={1500} onClick={showNavbar}> Painting Applications</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="Sealing&Dispensing" spy={true} smooth={true} offset={-110} duration={1500} onClick={showNavbar}> Sealing and Dispensing</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="Handling" spy={true} smooth={true} offset={-110} duration={1500} onClick={showNavbar}>Handling Applications</Link>
				<div className={styles.companyName}>
					TECHNAVIA INDIA PVT LTD ©
				</div>
				<button
					className={`${styles.navBtn} ${styles.navCloseBtn}`}
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className={styles.navBtn}
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default AppNav