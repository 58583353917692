import React from 'react'
import Navbar from './HomePageComponents/NewNav/Navbar'
import MainHomeScreen from './HomePageComponents/HomeScreen/MainHomeScreen'
import AboutUs from './HomePageComponents/Aboutus/AboutUs'
import MainCounter from './HomePageComponents/ProjectsCounter/MainCounter'
import OurValues from './HomePageComponents/OurValues/OurValues'
import AppCard from './HomePageComponents/ApplicationsCards/AppCard'
import Machine from './HomePageComponents/ourProducts/Machines'
import Parallax from './HomePageComponents/ServicesHome/Parallax'
import Footer from './HomePageComponents/Footer/Footer'
import Socials from './HomePageComponents/Socials/Socials'
import Testimonial from './HomePageComponents/Testimonials/Testimonial'
import NewsComponent from './HomePageComponents/News/NewsComponent'
import Marquee from './HomePageComponents/Marquee/Marquee'

function HomePage() {
  return (
    <div>
        <Navbar/>
        <MainHomeScreen/>

    {/*id to three components so that the nav bar keeps on glowing when the maincounter and our values are on screen */}
        <div id="AboutUs">
        <AboutUs/>
        <MainCounter/>
        <OurValues/>
        </div>

        <AppCard/>
        <Machine/>
        <Parallax/>
        <div id="Clients">
        <Testimonial />
        </div>
        <Marquee/>
        <NewsComponent/>
        <Socials/>
        <Footer/>
    </div>
  )
}

export default HomePage