import React from 'react'
import styles from './MainHomeScreen.module.css'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ImageText from './ImageText';
import home1 from "../../../../assets/homescreen/TIPLbuild1.jpg"
import home2 from "../../../../assets/homescreen/homescreen3.jpg"
import home3 from "../../../../assets/homescreen/homescreen1.jpg"
import home4 from "../../../../assets/homescreen/homescreen4.jpg"
import home5 from "../../../../assets/homescreen/homeScreen5.png"
import home6 from "../../../../assets/homescreen/home6.png"
function MainHomeScreen() {

    const images = [
        {
          img: home1,
          heading: "One Stop Solution",
          content: 'From conception to execution.'
        },
        {
          img: home2,
          heading: 'Building Future',
          content: 'Propelled by new technology.'
        },
        {
          img: home3,
          heading: 'Elevating Operations',
          content: 'With our advanced control system.'
        },
        {
          img: home4,
          heading: 'Your Partner',
          content: 'In Robotic Automation Excellence.'
        },
        {
          img: home5,
          heading: 'Changing Ideas',
          content: 'Into exceptional software solutions.'
        },
        {
          img: home6,
          heading: 'Ideas Evolved',
          content: 'Where concept meet functionality',
        },
      
        
      ];

      const indicators = (index) => (<div className={styles.indicator}></div>);


      const buttonStyle = {
        width: "20px",
        background: 'none',
        border: '0px'
    };
      const properties = {
        
        prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff" transform="matrix(-1, 0, 0, 1, 0, 0)"><path d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005
			L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251
			l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"/></svg></button>,
      nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005 L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251 l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"/></svg></button>
    }

  return (
    <div className={styles.Wrapper} id="/">

    <div className={styles.mainContainer}>
         
        <Fade  indicators={indicators} scale={1.4} {...properties} duration={3000} infinite={true} autoplay={true}>
        {images.map((image, index) => (
          <div className={styles.eachSlideEffect}>
            <div className={styles.bg} style={{ backgroundImage: `url(${image.img})` }}>
              <span></span>
              <ImageText key={index} heading={image.heading} content={image.content} />
            </div>
          </div>
        ))}
               
           
        </Fade>
    </div>
    </div>
  )
}

export default MainHomeScreen