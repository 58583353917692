import React from "react";
import styles from "./Values.module.css";
import {motion} from "framer-motion"

function Values(props) {
  return (
    <motion.div
    initial={{ opacity: 0, y: 110 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.1, duration: 1.2 }}
     className={styles.values}>
      <div
        className={styles.icon}
        style={{ backgroundImage: `url(${props.icon})` }}
      ></div>
      <div className={styles.valueText}>
        <div className={styles.heading}>
            {props.head}:
        </div>
        <div className={styles.content}>
            {props.content}
        </div>
      </div>
    </motion.div>
  );
}

export default Values;
