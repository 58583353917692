import React, { useState } from "react";
import styles from "./ProductImages.module.css";
import productData from "../../../../dummydata";
import Playimage from "../../../../assets/PlayButton.jpg"
function ProductImages({ id }) {
  const product = productData.find((product) => product.id === id);

  const [currImage, setCurrImage] = useState(0);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainImageContainer}>
        
      {currImage === 11 ? (
  <iframe
    className={styles.mainImage}
    src={product.video}
    title="YouTube video player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
  ></iframe>
) : currImage === 12 ? (
  <iframe
    className={styles.mainImage}
    src={product.video1}
    title="YouTube video player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
  ></iframe>
) : (
  <div
    className={styles.mainImage}
    style={{
      backgroundImage: `url(${product.imgSrc[currImage]})`,
      backgroundSize: "contain",
      backgroundRepeat:"no-repeat",
      backgroundPosition: "center",
    }}
  ></div>
)}

      </div>
      <div className={styles.thumbContainer}>
        <div className={styles.thumbnails}>
          {product.imgSrc.map((img, index) => (
            <div
              key={index}
              className={styles.media}
              style={{
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                
              }}
              onClick={(e) => setCurrImage(index)}
            >
            </div>
          ))}
        {![ 8, 7 , 4].includes(product.id) && ( //the numbers that you will put here will not have a logo for videos for iframe
  <div className={styles.media1} style={{backgroundImage: `url(${Playimage})`, backgroundSize: "cover"}} onClick={(e) => setCurrImage(11)}></div>
)}
        {[1].includes(product.id) && ( //the numbers that you will have two videos
  <div className={styles.media1} style={{backgroundImage: `url(${Playimage})`, backgroundSize: "cover"}} onClick={(e) => setCurrImage(12)}></div>
)}

        </div>
      </div>
    </div>
  );
}

export default ProductImages;
