import React from "react";
import styles from "./OurValuesTxt.module.css";
import iconQuality from "../../../../assets/OurValues/quality.png";
import iconInnovation from "../../../../assets/OurValues/innovation2.png";
import iconCommitment from "../../../../assets/OurValues/Commitment.png";
import Values from "./Values";
import { motion } from "framer-motion";
function OurValuesTxt() {
  return (
    <div className={styles.mainContainer}>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "90px" }}
        whileInView={{ width: "90px" }}
        // viewport={{ once: true }}
        transition={{ duration: 1 }}
        className={styles.redLine}
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        // viewport={{ once: true }} 
        transition={{ delay: 0.1, duration: 0.5 }}
        className={styles.heading}
      >
        Our Values
      </motion.div>
      <motion.div
        className={styles.valueWrapper}
      >
        <Values
          icon={iconQuality}
          head={"Quality"}
          content={
            "Quality forms the backbone of every system delivered by Technavia. Our commitment to quality management permeates every aspect of our operations. At Technavia, we ensure that every product we deliver is equipped with premium-grade components, ensuring the highest quality standards."
          }
        />
        <Values
          icon={iconInnovation}
          head={"Innovation"}
          content={
            "Technavia is committed to pioneering innovation in both our products and engineering solutions. We believe that innovation flourishes when our dedicated team of Technavia apply their creativity to exceed the needs and expectations of our clients. At Technavia, innovation is ingrained in our working culture, driving us to transform unique ideas into exceptional end products."
          }
        />
        <Values
          icon={iconCommitment}
          head={"Commitment"}
          content={
            "At Technavia, we cherish the commitment we make to every individual within our ecosystem. Our unwavering dedication to our clients is evident in our actions and embedded in our work culture. Every member of the Technavia family takes pride in upholding their commitment to delivering top-notch products and craftsmanship, ensuring unparalleled satisfaction for our customers."
          }
        />
      </motion.div>
    </div>
  );
}

export default OurValuesTxt;
