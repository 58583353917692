import React, { useEffect, useRef } from "react";
import styles from "./MainCounter.module.css";

import robots from "../../../../assets/ProjectCounterImages/robotsIntalled.png";
import prCompleted from "../../../../assets/ProjectCounterImages/ProjectsCompleted.png";
import XP from "../../../../assets/ProjectCounterImages/Experience.png";
import engineers from "../../../../assets/ProjectCounterImages/engineers.png";
import { animate, useInView } from "framer-motion";
import { motion } from "framer-motion";
import Textcomponent from "../../../branding/Textcomponent";

function Counter({ from, to }) {
  const nodeRef = useRef();
  const isInView = useInView(nodeRef, {once: true});

  useEffect(() => {
    const node = nodeRef.current;

    let controls;

    if (isInView && !controls) {
      controls = animate(from, to, {
        duration: 1.5,
        onUpdate(value) {
          node.textContent = value.toFixed(0) + "+";
        },
      });
    }

    return () => {
      if (controls) {
        controls.stop();
      }
    };
  }, [from, to, isInView]);

  return <p ref={nodeRef}></p>;
}





function MainCounter() {
  return (
    <>
    <Textcomponent Heading='Figures at a Glance' Content='With each milestone our stats speak volumes of our commitment to excellence and innovation '/>
    <div className={styles.mainwrapper}>

    <div className={styles.mainContainer}>
      <div className={styles.overlay}>
        <div className={styles.wrappers}>
          <div className={styles.border}>
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${robots})` }}
              ></div>
            <div className={styles.valueContainer}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                className={styles.number}
                >
                <Counter from={0} to={500} />
              </motion.div>
              <div className={styles.textvalue}>Robots Installed</div>
            </div>
          </div>
        </div>
        <div className={styles.seprator}></div>

        <div className={styles.wrappers}>
          <div className={styles.border}>
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${prCompleted})` }}
              ></div>
            <div className={styles.valueContainer}>
            <motion.div
                initial="hidden"
                whileInView="visible"
                className={styles.number}
                >
                <Counter from={0} to={175} />
              </motion.div>


              <div className={styles.textvalue}>Projects Completed</div>
            </div>
          </div>
        </div>

        <div className={styles.seprator}></div>

        <div className={styles.wrappers}>
          <div className={styles.border}>
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${XP})` }}
              ></div>
            <div className={styles.valueContainer}>
              <div className={styles.number}>
                <Counter from={0} to={18} />
              </div>
              <div className={styles.textvalue}>Years Industry Experience</div>
            </div>
          </div>
        </div>

        <div className={styles.seprator}></div>

        <div className={styles.wrappers}>
          <div className={styles.border}>
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${engineers})` }}
              ></div>
            <div className={styles.valueContainer}>
              <div className={styles.number}>
                <Counter from={0} to={100} />
              </div>

              <div className={styles.textvalue}>Qualified Engineers</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
              </>
  );
}

export default MainCounter;
