import React from "react";
import styles from "./Socials.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import Textcomponent from "../../../branding/Textcomponent";
function Socials() {
  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };
  
  return (
    <>
      <Textcomponent Heading={"Get In Touch"} Content={"Want to connect? We're just a message away"}/>
    <div className={styles.mainContainer} id="ContactUs">
      <button onClick={() => handleButtonClick("https://www.facebook.com/technaviaindia/")} className={styles.btn}>
        <FontAwesomeIcon icon={faFacebookF} style={{color:"#1877F2"}} size="2xl" />
      </button>
      <button onClick={() => handleButtonClick("https://www.youtube.com/@technaviaindia4835/videos")} className={styles.btn}>
        <FontAwesomeIcon icon={faYoutube} style={{color:"#FF0000"}} size="2xl" />
      </button>
      <button onClick={() => handleButtonClick("https://twitter.com/technaviaindia")} className={styles.btn}>
        <FontAwesomeIcon icon={faXTwitter} size="2xl" />
      </button>
      <button onClick={() => handleButtonClick("https://www.linkedin.com/company/technavia10/")} className={styles.btn}>
        <FontAwesomeIcon icon={faLinkedinIn} style={{color:"#0077b5"}} size="2xl" />
      </button>
    </div>
    </>
  );
  
}

export default Socials;
