import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import {Link} from 'react-scroll'
import styles from "./Navbar.module.css"

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			styles.responsiveNav
		);
	};
	

	return (
		<header>
            <Link to="/" spy={true} smooth={true} offset={0} duration={1500} className={styles.companylogo}>
            </Link>
			<nav ref={navRef}>
				
				<Link activeClass={styles.selected} className={styles.hov} to="/" spy={true} smooth={true} offset={0} duration={1500} onClick={showNavbar}> Home</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="AboutUs" spy={true} smooth={true} offset={-180} duration={1500} onClick={showNavbar}> About us</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="Applications" spy={true} smooth={true} offset={-180} duration={1500} onClick={showNavbar}> Applications</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="Products" spy={true} smooth={true} offset={-110} duration={1500} onClick={showNavbar}>Products</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="Services" spy={true} smooth={true} offset={-350} duration={1500} onClick={showNavbar}>Services</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="Clients" spy={true} smooth={true} offset={-140} duration={1500} onClick={showNavbar}>Our Clients</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="News" spy={true} smooth={true} offset={-150} duration={1500} onClick={showNavbar}>News</Link>
				<Link activeClass={styles.selected} className={styles.hov} to="ContactUs" spy={true} smooth={true} offset={50} duration={1500} onClick={showNavbar}>Reach Us</Link>
				<div className={styles.companyName}>
					TECHNAVIA INDIA PVT LTD ©
				</div>
				<button
					className={`${styles.navBtn} ${styles.navCloseBtn}`}
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className={styles.navBtn}
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;