
import sealing1 from "../../../assets/ApplicationsPhotos/sealing1.png";
import sealing2 from "../../../assets/ApplicationsPhotos/sealing2.jpg";
import sealing3 from "../../../assets/ApplicationsPhotos/sealing3.jpg";

import handling1 from "../../../assets/ApplicationsPhotos/handling1.png"
import handling2 from "../../../assets/ApplicationsPhotos/handling2.png"
import handling3 from "../../../assets/ApplicationsPhotos/handling3.png"
const SealingHandlingData = [
  {
    content: (
      <>
        <p>
          In the automotive industry, sealing plays a pivotal role, directly
          impacting the quality and longevity of the final product. Beyond
          waterproofing and rust prevention, sealing is integral for noise
          reduction, enhancing overall comfort and driving experience. Multiple
          processes are involved in applying sealants or soundproofing materials
          to the interior and underbody of automobiles.
        </p>
        <br />
        <p>
          Precision is paramount in sealing applications, making robotics a
          crucial component. Technavia not only offers robotic solutions for
          sealing applications but also provides comprehensive sealant feeding
          arrangements, ensuring efficiency and accuracy.
        </p>
        <br />
        <p>
          Technavia excels in providing robotic automated solutions for
          windshield sealing, hot melt dispensing in the lighting industry, and
          various other dispensing applications essential in the automotive and
          general industries. We have successfully implemented numerous robotic
          systems for underbody
        </p>
        <br />
        <p>
          sealing and PVC coating in major OEMs. Our turnkey solutions encompass
          everything from pumps, dosing units, and dispensing equipment to
          automation, streamlining the entire process.
        </p>
        <br />
        <p>
          In our sealing application solutions, Technavia's product line
          includes a seventh-axis slider, enhancing robot accessibility during
          underbody sealing and PVC coating application in car bodies or truck
          cabins.
        </p>
        <br />
        <p>
          Technavia has been at the forefront of automation solutions for a
          diverse range of sealing and dispensing processes, including:
        </p>
        <br />
        <ul>
          <li>
            PVC Seam Sealing: Filling gaps with PVC paint to ensure seamless
            joints.
          </li>
          <li>
            LASD (Liquid Applied Sound Deadener): Applying soundproof materials
            to reduce noise by dampening vibrations from the underbody.
          </li>
          <li>
            UBS (Under Body Sealing): Applying synthetic rubber sealant by
            robots to seal gaps between steel sheets.
          </li>
          <li>
            UBC (Under Body Coat-Deadener): Applying dark grey PVC resin coating
            to the floor surface of automobile underbodies.
          </li>
          <li>
            RPP (Rocker Panel Protector): Applying urethane-based paint to
            prevent cracks and damage to the automobile underbody, including the
            bottom part of fenders and skirt panels.
          </li>
        </ul>

        <br />
        <p>
          Trust Technavia for cutting-edge automation solutions that optimize
          sealing and dispensing processes, ensuring durability, quality, and
          efficiency across the automotive industry.
        </p>
      </>
    ),
    extraImages: [sealing3, sealing2, sealing1],
  },
  {
    content: (
      <>
        <p>
          Technavia offers robust solutions for automated machine tending
          applications, utilizing either robotic arms or customized systems to
          seamlessly transfer components. Given the diverse nature of handling
          tasks, which vary based on processes, component types, and
          manufacturing applications, we take full turnkey responsibility for
          automation solutions.
        </p>
        <br />
        <p>
          We specialize in providing gantry and rail systems designed to
          maximize efficiency by accommodating multiple machines within a single
          line, utilizing either a single robot or customized handling methods.
          At Technavia, we prioritize innovation by integrating cutting-edge
          gripping solutions to deliver highly efficient systems.
        </p>
        <br />
        <p>
          Our approach to automatic handling systems incorporates modern
          technologies such as vision systems, enabling fixed programming
          routines to adapt to various production variants without the need for
          extensive reprogramming. By seamlessly interfacing robotic systems
          with vision technology, we create comprehensive solutions that meet
          the evolving requirements of modern manufacturing techniques.{" "}
        </p>
        <br />
        <p>
          Recognizing the growing need for collaboration between humans and
          robots in industry, Technavia has developed expertise in installing
          handling and sealing systems featuring collaborative robots, or
          "cobots." Leveraging our rich experience, we ensure seamless
          integration and operation of cobot systems to enhance productivity and
          safety in manufacturing environments. Trust Technavia for innovative
          automation solutions tailored to your specific needs, driving
          efficiency and success in your operations.{" "}
        </p>
      </>
    ),
    extraImages: [handling1, handling2, handling3],
  },
];

export default SealingHandlingData;
