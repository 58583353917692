import React, { useEffect } from "react";
import styles from "../Applications/Applications.module.css";
import PaintingAppCards from "./components/PaintingAppCards";
import SealingDespensingCard from "./components/SealingDespensingCard";
import handling from "../../../assets/Home3.jpg"
import mainsealing from "../../../assets/ApplicationsPhotos/mainsealing.png"
import SealingHandlingData from "./SealingHandlingData";
import AppNav from "./components/AppNav";
import { useLocation } from "react-router-dom";

function ScrollToTopOnMount() {
  const { services } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [services]);

  return null;
}


const Applications=( ) =>{
  const { id } = 'test';

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [id]);
 

  return (
    < >
    <ScrollToTopOnMount/>
    <div className={styles.Wrapper}>
      <AppNav/>
      <div className={styles.mainContainer}>
      <div  className={styles.mainHeading1}>
      < div className={styles.head}>
      <div className={styles.redLine}></div>
            <p>Applications</p>
      </div>
            <h6>Technavia specializes in comprehensive painting automation solutions, offering turnkey projects
that encompass the entire spectrum, from paint kitchen setup to robotics integration, applicator
selection, and paint dosing equipment. Our expertise lies in tailoring the most suitable system by
meticulously choosing the automation process, applicator, and paint circulation system.
Our offerings include advanced features such as servo rail tracks for robot mobility and servo
feeders for precise component handling, seamlessly synchronized with external axes to optimize
cycle times and painting methodologies. Over the years, we have successfully executed numerous
painting projects across diverse sectors including automotive, non-automotive, general industry,
and white goods.
<br/>
At Technavia, versatility is key. We have adeptly employed various applicator types including
conventional guns, bells, and electrostatic guns, always prioritizing system compatibility and
effectiveness. Moreover, we have innovated a reciprocator system specifically tailored for budget-
conscious projects, ensuring optimal performance with any chosen applicator type.</h6>
          </div>

        <div id="Painting">

        <div className={styles.headingWrapper}>
          <div  className={styles.mainHeading}>
            <div className={styles.redLine}></div>
            <p>Painting Application</p>
          </div>
        </div>
        <PaintingAppCards  />
        </div>

    <div id ="Sealing&Dispensing">
        <div className={styles.headingWrapper}>
          <div className={styles.mainHeading}>
            <div   className={styles.redLine}></div>
            <p>Sealing and Dispensing</p>
          </div>
        </div>
        <SealingDespensingCard  pic={mainsealing} Content={SealingHandlingData[0].content} number={0}/>
    </div>

        <div id="Handling">
        <div  className={styles.headingWrapper}>
          <div  className={styles.mainHeading}>
            <div className={styles.redLine}></div>
            <p>Handling Application</p>
          </div>
        </div>
        <SealingDespensingCard  pic={handling} Heading={""} Content={SealingHandlingData[1].content} number={1}/>
        </div>
      </div>
    </div>
    </>
  );
}

export default Applications;
