import React, { useState } from "react";
import styles from "./SealingDespensingCard.module.css";
import ExtraImages from "./ExtraImages";
import SealingHandlingData from "../SealingHandlingData";
function SealingDespensingCard({Heading , Content , number}) {

  const [selectedImageIndexes, setSelectedImageIndexes] = useState(Array(SealingHandlingData.length).fill(0));

  const handleImageClick = (id, index1) => {
    setSelectedImageIndexes(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[id] = index1;
      return newIndexes;
    });
  };
  return (
    <div className={styles.mainContainer}>
        
      <div className={styles.rightContainer}>
        <div className={styles.heading}>
          <h1>{Heading}</h1>
        </div>
        <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${SealingHandlingData[number]?.extraImages[selectedImageIndexes[number]]})` }}
          ></div>
        <ExtraImages images={SealingHandlingData[number]?.extraImages || []}
        onImageClick={(index1) =>handleImageClick(number ,index1)} />

     
      </div>

      <div className={styles.leftContainer}>
        <p>{Content}</p>
      </div>
    
    </div>
  );
}

export default SealingDespensingCard;
