import React from "react";
import styles from "./Parallax.module.css";
import Upgrade from "../../../../assets/servicesIcon/upgrade.png";
import design from "../../../../assets/servicesIcon/designing.png";
import inventory from "../../../../assets/servicesIcon/inventory.png";
import teaching from "../../../../assets/servicesIcon/teaching.png";
import training from "../../../../assets/servicesIcon/training.png";
import electrical from "../../../../assets/servicesIcon/electricity.png";
import robotic from "../../../../assets/servicesIcon/roboticpainting.png"
import pretrial from "../../../../assets/servicesIcon/pretrials.png"
import mechanical from "../../../../assets/servicesIcon/mechanical.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Parallax = () => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.test}>
        <div className={styles.redLine}></div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className={styles.heading1}
        >
          Our Services
        </motion.div>
        <p>
          Technavia delivers top-tier service, ensuring system stability through
          proactive maintenance, customized training, and swift issue
          resolution, fostering trust and reliability. Trust us for
          comprehensive service support that keeps your systems running smoothly
          and efficiently.
        </p>
      </div>

      <div className={styles.mainwrapper} id="Services">
        <div className={styles.parallaxContainer}>
          <div className={styles.contentBox}>
            <div className={styles.iconContainer}>
              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${Upgrade})` }}
                  ></div>
                  <div className={styles.heading}>System Upgradation</div>
                </Link>
              </div>

              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${teaching})` }}
                  ></div>
                  <div className={styles.heading}>Robot teaching</div>
                </Link>
              </div>

              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${training})` }}
                  ></div>
                  <div className={styles.heading}>Training</div>
                </Link>
              </div>
              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${design})` }}
                  ></div>
                  <div className={styles.heading}>Design engineering</div>
                </Link>
              </div>

              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${mechanical})` }}
                  ></div>
                  <div className={styles.heading}>Mechanical manufacturing</div>
                </Link>
              </div>
              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${electrical})` }}
                  ></div>
                  <div className={styles.heading}>Electrical manufacturing</div>
                </Link>
              </div>

              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${robotic})` }}
                  ></div>
                  <div className={styles.heading}>Robotic Painting Trial</div>
                </Link>
              </div>

              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${pretrial})` }}
                  ></div>
                  <div className={styles.heading}>Pre-Installation Testing</div>
                </Link>
              </div>

              <div className={styles.deco}>
                <Link to="/services" className={styles.compo}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${inventory})` }}
                  ></div>
                  <div className={styles.heading}>Spare part inventory</div>
                </Link>
              </div>
            </div>

            <Link to="/services">
              <button className={styles.servicebutton}>Learn More</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parallax;
