import React from 'react'
import styles from './ExtraImages.module.css'

function ExtraImages({ images, onImageClick }) {
  const handleClick = (index1) => {
    onImageClick(index1);
  };

  return (
    <div className={styles.mainContainer}>
      {images.map((image, index1) => (
        <div
          key={index1}
          className={styles.image}
          style={{ backgroundImage: `url(${image})` }}
          onClick={() => handleClick(index1)}
        ></div>
      ))}
    </div>
  );
}


export default ExtraImages